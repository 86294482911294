import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../Components/Common/Layout";
import Banner from "../images/partners/partners-banner.jpg";
import Mx from "../images/partners/mxplayer.png";
import Tik from "../images/partners/tiktok.png";
import Facebook from "../images/partners/facebook.png";
import Youtube from "../images/partners/youtube.png";
import Itunes from "../images/partners/itunes.png";
import Insta from "../images/partners/instagram.png";
import Saavn from "../images/partners/saavn.png";
import Gaana from "../images/partners/gaana.png";
import Wynk from "../images/partners/wynk.png";
import Hungama from "../images/partners/hungama.png";
import Apple from "../images/partners/applemusic.png";
import Spotify from "../images/partners/spotify.png";
import Resso from "../images/partners/resso.png";
import AmazonMusic from "../images/partners/amazonmusic.png";
import Iprs from "../images/partners/iprs.png";
import Sony from "../images/partners/sonyatv.png";
import AsCap from "../images/partners/ascap.png";
import Prs from "../images/partners/prs.png";
import Macp from "../images/partners/macp.png";
import Ppl from "../images/partners/ppl.png";

import Mano from "../images/partners/manorama.png";
import Kosmik from "../images/partners/kosmik.png";
import Mrs from "../images/partners/mrs.png";

import Wunder from "../images/partners/wunderbar.png";
import Lyca from "../images/partners/lyca.png";
import Ondraga from "../images/partners/ondraga.png";

import Kalai from "../images/partners/kalaipuli.png";
import Suntv from "../images/partners/suntv.png";
import Param from "../images/partners/paramvah.png";
import Jio from "../images/partners/jiostudios.png";
import Dummy from "../images/partners/dummy.png";
import AnirudhRavichander from "../images/partners/Anirudh Ravichander.jpg";
import Bhat from "../images/partners/ananya.png";
import RaghuDixit from "../images/partners/RaghuDixit.jpg";
import Dj from "../images/partners/djbravo.png";
import Staccato from "../images/partners/staccata.png";

import Curve from "../images/msline.png";

import Curve1 from "../images/labelline.png";

import Curve2 from "../images/artline.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Partners = () => {
  return (
    <div className="partners">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partners</title>
      </Helmet>
      <Layout>
        <div className="banner">
          <img src={Banner} alt="banner" />
          <div className="banner-text container">
            <h3 data-aos="fade-right" data-aos-duration="1000">
              Partners
            </h3>
          </div>
        </div>
        <div className="container">
          <div className="row-1">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="1000">
                  Video
                </h3>
              </div>
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="100"
                    >
                      <img src={Mx} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="200"
                    >
                      <img src={Tik} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      data-aos-offset="200"
                    >
                      <img src={Facebook} alt="Facebook" />
                    </div>
                  </div>

                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="400"
                    >
                      <img src={Youtube} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="500"
                    >
                      <img src={Itunes} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="600"
                    >
                      <img src={Insta} alt="Facebook" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-2">
            <div className="cu-line">
              <img src={Curve} alt="curve" />
            </div>
            <div className="row-content">
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img1">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="100"
                    >
                      <h4>
                        Digital
                        <br /> Stores
                      </h4>
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="200"
                    >
                      <img src={Saavn} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      data-aos-offset="200"
                    >
                      <img src={Gaana} alt="Facebook" />
                    </div>
                  </div>

                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="400"
                    >
                      <img src={Wynk} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="500"
                    >
                      <img src={Hungama} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="600"
                    >
                      <img src={Apple} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="700"
                    >
                      <img src={Spotify} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="800"
                    >
                      <img src={Resso} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="900"
                    >
                      <img src={AmazonMusic} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img2">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1000"
                    >
                      <h4>
                        Mobile
                        <br /> Operators
                      </h4>
                    </div>
                  </div>
                  <div className="text-img3">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1100"
                    >
                      <h4>
                        Radio
                        <br /> Stations
                      </h4>
                    </div>
                  </div>
                  <div className="text-img4">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1200"
                    >
                      <h4>
                        Tv
                        <br /> Channels
                      </h4>
                    </div>
                  </div>
                  <div className="text-img5">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1300"
                    >
                      <h4>
                        &<br /> more
                      </h4>
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1400"
                    >
                      <img src={Dummy} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img6">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1500"
                    >
                      <img src={Dummy} alt="Facebook" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text">
                <h3
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-delay="1000"
                >
                  Music Stores
                </h3>
              </div>
            </div>
          </div>

          <div className="row-3">
            <div className="row-content">
              <div className="text">
                <h3
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-delay="1000"
                >
                  Publishing & Neighbouring Rights
                </h3>
              </div>
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="100"
                    >
                      <img src={Iprs} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="200"
                    >
                      <img src={Sony} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="300"
                      data-aos-offset="200"
                    >
                      <img src={AsCap} alt="Facebook" />
                    </div>
                  </div>

                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="400"
                    >
                      <img src={Prs} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="500"
                    >
                      <img src={Macp} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="600"
                    >
                      <img src={Ppl} alt="Facebook" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-4 ">
          <div className="container-fluid">
            <div className="text">
              <h3 data-aos="fade-up" data-aos-delay="300" data-aos-offset="200">
                Clients
              </h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row-5">
            <div className="cu-line">
              <img src={Curve1} alt="curve" />
            </div>
            <div className="row-content">
              <div className="text">
                <h3
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-delay="1000"
                >
                  Labels
                </h3>
              </div>
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img-1">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="400"
                    >
                      <img src={Mano} alt="player" />
                    </div>
                  </div>
                  <div className="text-img-1">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="500"
                    >
                      <img src={Kosmik} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img-1">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="600"
                    >
                      <img src={Mrs} alt="Facebook" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-6">
            <div className="row-content">
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="700"
                    >
                      <img src={Wunder} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="800"
                    >
                      <img src={Lyca} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="900"
                    >
                      <img src={Ondraga} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1000"
                    >
                      <img src={Kalai} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1100"
                    >
                      <img src={Suntv} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1200"
                    >
                      <img src={Param} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1300"
                    >
                      <img src={Jio} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1400"
                    >
                      <img src={Dummy} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img-11">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="1500"
                    >
                      <img src={Dummy} alt="Facebook" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text">
                <h3
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-delay="1000"
                >
                  Studios / Right holders
                </h3>
              </div>
            </div>
          </div>

          <div className="row-7">
            <div className="cu-line">
              <img src={Curve2} alt="curve" />
            </div>
            <div className="artists">
              <div className="art-text">
                <h3 data-aos="fade-down" data-aos-delay="100">
                  Artists & Musicians
                </h3>
              </div>
              <div className="artists-group">
                <div className="artists">
                  <div
                    className="artist-1 each-art"
                    data-aos="fade-right"
                    data-aos-delay="100"
                    data-aos-offset="200"
                  >
                    <img src={AnirudhRavichander} alt="ysr" />
                    <div className="text">
                      <h5>Anirudh Ravichander</h5>
                    </div>
                  </div>
                  <div
                    className="artist-2 each-art"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-offset="200"
                  >
                    <img src={Bhat} alt="Bhat" />
                    <div className="text">
                      <h5>Ananya Bhat</h5>
                    </div>
                  </div>
                  <div
                    className="artist-3 each-art"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-offset="200"
                  >
                    <img src={RaghuDixit} alt="ysr" />
                    <div className="text">
                      <h5>Raghu Dixit</h5>
                    </div>
                  </div>
                  <div
                    className="artist-4 each-art"
                    data-aos="fade-right"
                    data-aos-delay="400"
                    data-aos-offset="200"
                  >
                    <img src={Dj} alt="ysr" />
                    <div className="text">
                      <h5>DJ Bravo</h5>
                    </div>
                  </div>
                  <div
                    className="artist-5 each-art"
                    data-aos="fade-right"
                    data-aos-delay="400"
                    data-aos-offset="200"
                  >
                    <img src={Staccato} alt="ysr" />
                    <div className="text">
                      <h5>Staccato</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row-2">
                <div className="cu-line">
                        <img src={Curve} alt="curve"/>
                    </div>
                    <div className="double-rows">
                            <div className="platform-1 rows">
                                <div className="text-img">
                                    <div className="icon" data-aos="fade-right" data-aos-delay="100">
                                        <img src={Mx} alt="player"/>
                                    </div>
                                </div>
                                <div className="text-img">
                                <div className="icon" data-aos="fade-right" data-aos-delay="200">
                                        <img src={Tik} alt="tiktok"/>
                                    </div>
                                </div>
                                <div className="text-img">
                                <div className="icon" data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                                        <img src={Facebook} alt="Facebook"/>
                                    </div>
                                </div>

                                <div className="text-img">
                                    <div className="icon" data-aos="fade-right" data-aos-delay="100">
                                        <img src={Youtube} alt="player"/>
                                    </div>
                                </div>
                                <div className="text-img">
                                <div className="icon" data-aos="fade-right" data-aos-delay="200">
                                        <img src={Itunes} alt="tiktok"/>
                                    </div>
                                </div>
                                <div className="text-img">
                                <div className="icon" data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                                        <img src={Insta} alt="Facebook"/>
                                    </div>
                                </div>
                            </div>
              
                        </div>
                       
                    
                <div className="row-content">
                      
                        
                        <div className="text">
                        <h3 data-aos="fade-right" data-aos-delay="1000">

                       Video
                        </h3>
                        </div>
                    </div>
                </div>
                 */}
      </Layout>
    </div>
  );
};
export default Partners;
