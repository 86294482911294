import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../Components/Common/Layout';
import Banner from '../images/royalties/royalty-cover.jpg';
import AnimationImg from '../images/royalties/r1.svg';
import AnimationMap from '../images/royalties/r2.svg';
import AnimationRadio from '../images/royalties/r3.svg';
import Curve from '../images/abline.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const PublishingRoyalties = () => {
  return (
    <div className="royalties">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Royalties</title>
      </Helmet>
      <Layout>
        <div className="banner ">
          <img src={Banner} alt="banner" />
          <div className="banner-text container">
            <h3
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Publishing Royalties
            </h3>
          </div>
        </div>
        {/* <div className="container"> */}
        <div className="row-1 container">
          <div className="row-content">
            <div className="text">
              <h3 data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                We collect all royalties related to your publishing and sound
                recording royalties worldwide and at source directly.
              </h3>
            </div>
            <div
              className="anim-img"
              data-aos="fade-right"
              data-aos-delay="800"
              data-aos-easing="ease-in-out"
            >
              <img src={AnimationImg} alt="anim" />
            </div>
          </div>
        </div>
        <div className="row-2 ">
          <div className="row-content container">
            <div className="anim-img" data-aos="fade-left" data-aos-delay="300" data-aos-offset="200">
              <img src={AnimationMap} alt="anim" />
            </div>
            <div className="text ">
              <h3 data-aos="fade-left" data-aos-delay="800">
                Only independent music distribution company in India with
                dedicated in house publishing team
              </h3>
            </div>
          </div>
          <div className="curve-line">
            <img src={Curve} alt="curve" />
          </div>
        </div>
        {/* </div> */}

        <div className="row-3">
          <div className="container-fluid">
            <h3 data-aos="fade-up" data-aos-delay="300" data-aos-offset="200">
              Direct relationships with copyright societies, Performance rights
              organizations, Collective management organizations, Digital
              Service Providers DSPs, and more.
            </h3>
          </div>
        </div>

        <div className="container">
          <div className="row-4">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                  These relationships ensure when your music gets played and we
                  collect royalties at source including from radio, tv, public
                  performance. Our monitoring team ensures your royalties are
                  identified, reported and collected.
                </h3>
              </div>
              <div
                className="anim-img"
                data-aos="fade-right"
                data-aos-delay="800"
              >
                <img src={AnimationRadio} alt="anim" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* <div className="loader">
                <div className="loader-animation">
                    <div className="loader-inner">
                        <div className="fill">
                            <img src={Loader} alt="loader"/>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};
export default PublishingRoyalties;
