import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { GoLocation } from "react-icons/go";
import Layout from "../Components/Common/Layout";
import axios from "axios";
import Select from "react-select";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const endpoint =
  process.env.REACT_APP_API_ENDPOINT ||
  "https://h4g9zyraq7.execute-api.ap-south-1.amazonaws.com/dev";

const Contact = () => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [successMessage2, setSuccessMessage2] = useState("");

  // handleChange2 = (selectedOption) => {
  //   setSelect({ selectedOption });
  //   document.write(`Option selected:`, selectedOption.value);
  // }
  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" }
  ];

  const [producerData, setProducerData] = useState({
    type: "producer",
    name: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    language: "",
    message: ""
  });
  const [artistData, setArtistData] = useState({
    type: "artist",
    name: "",
    // companyName: "",
    phoneNumber: "",
    email: "",
    language: "",
    message: ""
  });
  var handleChange = (e, str) => {
    setShow(str);
    if (show !== str) {
      setErrorMessage("");
      setPhoneMessage("");
      setSuccessMessage("");
    }
    e.preventDefault();
  };

  const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const phonePattern = new RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const captureProducerData = event => {
    // console.log("event", event.value);
    if (event.target) {
      const name = event.target.name;
      const value = event.target.value;
      setProducerData(previousState => ({
        ...previousState,
        [name]: value
      }));
      if (event.target.name === "phoneNumber") setPhoneMessage("");
      if (event.target.name === "email") setErrorMessage("");
    } else {
      const value = event.value;
      setProducerData(previousState => ({
        ...previousState,
        language: value
      }));
    }
  };
  // console.log(producerData);
  const captureArtistData = event => {
    if (event.target) {
      const name = event.target.name;
      const value = event.target.value;
      setArtistData(previousState => ({
        ...previousState,
        [name]: value
      }));
      if (event.target.name === "phoneNumber") setPhoneMessage("");
      if (event.target.name === "email") setErrorMessage("");
    } else {
      const value = event.value;
      setArtistData(previousState => ({
        ...previousState,
        language: value
      }));
    }
  };
  const producer = event => {
    event.preventDefault();
    if (
      producerData.name === "" ||
      producerData.company === "" ||
      producerData.phoneNumber === "" ||
      producerData.email === "" ||
      producerData.language === "" ||
      producerData.message === "" ||
      !emailPattern.test(producerData.email) ||
      !phonePattern.test(producerData.phoneNumber)
    ) {
      // if(!emailPattern.test(producerData.email)){
      //   setErrorMessage("Invalid Email")
      // }
      console.log("submit all");
      return;
    } else {
      axios
        .post(`${endpoint}/contact-us`, producerData)
        .then(response => {
          console.log(response);
          setSuccessMessage("We have received your query.");
          setProducerData({
            type: "producer",
            name: "",
            companyName: "",
            phoneNumber: "",
            email: "",
            language: "",
            message: ""
          });
        })
        .catch(error => {
          console.error("There was an error!", error);
          // console.log("Producer data", producerData);
        });
    }
  };
  const artist = event => {
    event.preventDefault();
    if (
      artistData.name === "" ||
      artistData.phoneNumber === "" ||
      artistData.email === "" ||
      artistData.language === "" ||
      artistData.message === "" ||
      !emailPattern.test(artistData.email) ||
      !phonePattern.test(artistData.phoneNumber)
    ) {
      console.log("submit all");
      // if(!emailPattern.test(producerData.email)){
      //   setErrorMessage("Invalid Email")
      //   return
      // }
      return;
    } else {
      axios
        .post(`${endpoint}/contact-us`, artistData)
        .then(response => {
          setSuccessMessage("We have received your query.");
          setArtistData({
            type: "artist",
            name: "",
            phoneNumber: "",
            email: "",
            language: "",
            message: ""
          });
        })
        .catch(error => {
          console.error("There was an error!", error);
          // console.log("artistData", artistData);
        });
    }
  };

  return (
    <div className="contact_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
      </Helmet>
      <Layout pageClass={Contact}>
        <div className="contact_content container">
          {/* <div className="contact_img">
              <img src={contact_image} alt="contact_image"/>
            </div> */}
          <div className="inner_text">
            <div className="contact_text">
              <h3
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="300"
                data-aos-offset="200"
              >
                Contact
              </h3>
            </div>
          </div>
          <div className="container-fluid ">
            <div
              className="contact_locs"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              <div className="loc_icon">
                <GoLocation className="go-location" />
              </div>
              <ul className="locarea">
                <li>
                  <span>Chennai</span>
                </li>
                <li>
                  <span>Bengaluru</span>
                </li>
                <li>
                  <span>Hyderabad</span>
                </li>
                <li>
                  <span>Cochin</span>
                </li>
              </ul>
            </div>
            <div className="contact_section ">
              <div
                className={show ? "choosebtn slideup" : "choosebtn"}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                <a
                  href="#"
                  className={
                    show === "producer" ? "producer active" : "producer"
                  }
                  onClick={e => handleChange(e, "producer")}
                >
                  Are you a label or a film producer?
                </a>
                <a
                  href="#"
                  className={show === "musician" ? "artist active" : "artist"}
                  onClick={e => handleChange(e, "musician")}
                >
                  Are you an artist or musician?
                </a>
              </div>
              <div className="form">
                {show === "producer" ? (
                  <div
                    className="formholder"
                    data-aos="fade-right"
                    data-aos-duration="300"
                  >
                    <div className="producer_form">
                      <form method="post" action="" className="form-group ">
                        <div className="form-row">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            required=""
                            value={producerData.name}
                            onChange={captureProducerData}
                          />
                          {/* <span style={{color:"red"}}>{error.errorMessage}</span> */}
                        </div>
                        <div className="form-row">
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company name"
                            required
                            value={producerData.companyName}
                            onChange={captureProducerData}
                          />
                        </div>
                        <div className="form-row">
                          <input
                            type="number"
                            name="phoneNumber"
                            placeholder="Phone number"
                            required
                            value={producerData.phoneNumber}
                            onBlur={() => {
                              if (!phonePattern.test(producerData.phoneNumber))
                                setPhoneMessage("Invalid phone number");
                            }}
                            onChange={captureProducerData}
                          />
                          {phoneMessage && (
                            <span style={{ color: "red" }}>{phoneMessage}</span>
                          )}
                        </div>
                        <div className="form-row">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                            value={producerData.email}
                            onBlur={() => {
                              if (!emailPattern.test(producerData.email))
                                setErrorMessage("Invalid email id");
                            }}
                            onChange={captureProducerData}
                          />
                          {errorMessage && (
                            <span style={{ color: "red" }}>{errorMessage}</span>
                          )}
                          {/* {test === false ?(<span style={{color:"red"}}>Invalid Email</span>):null} */}
                        </div>
                        <div className="form-row">
                          {/* <select
                            className="selectpicker"
                            name="language"
                            title="Language"
                            required
                            onChange={captureProducerData}
                          >
                            <option value="" selected>
                              Language
                            </option>
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>
                          </select> */}
                          <Select
                            placeholder="Language"
                            isSearchable={false}
                            classNamePrefix="customSelect"
                            className="customSelect"
                            onChange={captureProducerData}
                            name="language"
                            value={
                              producerData.language
                                ? {
                                    label: producerData.language,
                                    value: producerData.language
                                  }
                                : null
                            }
                            options={options}
                          />
                        </div>
                        <div className="form-row">
                          <input
                            type="text"
                            name="message"
                            placeholder="Message"
                            required
                            onChange={captureProducerData}
                            value={producerData.message}
                          />
                        </div>
                        <div className="form_submit">
                          <div className="form-row">
                            <button
                              type="submit"
                              className="send"
                              disabled={
                                producerData.name === "" ||
                                producerData.company === "" ||
                                producerData.phoneNumber === "" ||
                                producerData.email === "" ||
                                producerData.language === "" ||
                                producerData.message === "" ||
                                !emailPattern.test(producerData.email) ||
                                !phonePattern.test(producerData.phoneNumber)
                              }
                              onClick={producer}
                            >
                              Send
                            </button>
                            {successMessage && (
                              <p
                                style={{
                                  color: "white",
                                  background: "#70c478",
                                  padding: "5px 10px",
                                  textAlign: "center",
                                  marginTop: "15px",
                                  borderRadius: "5px"
                                }}
                              >
                                {successMessage}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* </div> */}
                  </div>
                ) : null}

                {show === "musician" ? (
                  <div
                    className="artist_form"
                    data-aos="fade-left"
                    data-aos-duration="300"
                  >
                    <form method="post" action="" className="form-group">
                      <div className="form-row">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                          value={artistData.name}
                          onChange={captureArtistData}
                        />
                      </div>
                      <div className="form-row">
                        <input
                          type="number"
                          name="phoneNumber"
                          placeholder="Phone number"
                          required
                          value={artistData.phoneNumber}
                          onBlur={() => {
                            if (!phonePattern.test(artistData.phoneNumber))
                              setPhoneMessage("Invalid phone number");
                          }}
                          onChange={captureArtistData}
                        />
                        {phoneMessage && (
                          <span className="error" style={{ color: "red" }}>
                            {phoneMessage}
                          </span>
                        )}
                      </div>
                      <div className="form-row">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                          value={artistData.email}
                          onBlur={() => {
                            if (!emailPattern.test(artistData.email))
                              setErrorMessage("Invalid email id");
                          }}
                          onChange={captureArtistData}
                        />
                        {/* <span style={{color:"red"}}>{errorMessage}</span> */}
                        {errorMessage && (
                          <span className="error" style={{ color: "red" }}>
                            {errorMessage}
                          </span>
                        )}
                      </div>
                      <div className="form-row">
                        {/* <select
                          className="selectpicker"
                          name="language"
                          title="Language"
                          required
                          onChange={captureArtistData}
                        >
                          <option value="" selected>
                            Language
                          </option>
                          <option value="english">English</option>
                          <option value="hindi">Hindi</option>
                        </select> */}
                        <Select
                          placeholder="Language"
                          isSearchable={false}
                          classNamePrefix="customSelect"
                          className="customSelect"
                          onChange={captureArtistData}
                          name="language"
                          options={options}
                          value={
                            artistData.language
                              ? {
                                  label: artistData.language,
                                  value: artistData.language
                                }
                              : null
                          }
                        />
                      </div>
                      <div className="form-row">
                        <input
                          type="text"
                          name="message"
                          placeholder="Message"
                          required
                          value={artistData.message}
                          onChange={captureArtistData}
                        />
                      </div>
                      <div className="form_submit">
                        <div className="form-row">
                          <button
                            type="submit"
                            className="send"
                            disabled={
                              artistData.name === "" ||
                              artistData.phoneNumber === "" ||
                              artistData.email === "" ||
                              artistData.language === "" ||
                              artistData.message === "" ||
                              !emailPattern.test(artistData.email) ||
                              !phonePattern.test(artistData.phoneNumber)
                            }
                            onClick={artist}
                          >
                            Send
                          </button>
                          {successMessage && (
                            <p
                              style={{
                                color: "white",
                                background: "#70c478",
                                padding: "5px 10px",
                                textAlign: "center",
                                marginTop: "15px",
                                borderRadius: "5px"
                              }}
                            >
                              {successMessage}
                            </p>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Contact;
