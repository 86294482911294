import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../Components/Common/Layout';
import Banner from '../images/why-divo/why-divo-banner.jpg';
import Dv1 from '../images/why-divo/dv1.svg';
import Dv2 from '../images/why-divo/dv2.svg';
import Dv3 from '../images/why-divo/dv3.svg';
import Dv4 from '../images/why-divo/dv4.svg';
import Dv5 from '../images/why-divo/dv5.svg';
import Abline from '../images/abline.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const WhyDivo = () => {
  return (
    <div className="whydivo">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Why Divo</title>
      </Helmet>
      <Layout>
        <div className="banner ">
          <img src={Banner} alt="banner" />
          <div className="banner-text container">
            <h3
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              Why Divo Music?
            </h3>
          </div>
        </div>
        <div className="container">
          <div className="row-1">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                  Our distribution network with global direct relationships with
                  all music services including Digital Service Providers
                  (DSP's), Video platforms, Telecom Mobile operators, Radio, TV,
                  etc with no middle men or hidden fees
                </h3>
              </div>
              <div
                className="anim-img"
                data-aos="fade-right"
                data-aos-delay="800"
                data-aos-easing="ease-in-out"
              >
                <img src={Dv1} alt="anim" />
              </div>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="row-content container">
            <div className="anim-img" data-aos="fade-left" data-aos-delay="300" data-aos-offset="200">
              <img src={Dv2} alt="anim" />
            </div>
            <div className="text">
              <h3 data-aos="fade-left" data-aos-delay="800">
                We are bootstrapped and not backed by a major label or a VC
                meaning we do not look at you as a target or just another rack
                in the catalog.
              </h3>
            </div>
          </div>
          <div className="curve-line">
            <img src={Abline} alt="curve" />
          </div>
        </div>

        <div className="row-3">
          <div className="container-fluid">
            <h3 data-aos="fade-up" data-aos-delay="300" data-aos-offset="200">
              Made in India
            </h3>
          </div>
        </div>

        <div className="container">
          <div className="row-4">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                  Hands on with your content by our team of professionals to see
                  your music succeed.
                </h3>
              </div>
              <div
                className="anim-img"
                data-aos="fade-right"
                data-aos-delay="800"
              >
                <img src={Dv3} alt="anim" />
              </div>
            </div>
          </div>
        </div>
        <div className="row-5">
          <div className="row-content container">
            <div className="anim-img" data-aos="fade-left" data-aos-delay="300" data-aos-offset="200">
              <img src={Dv4} alt="anim" />
            </div>
            <div className="text">
              <h3 data-aos="fade-left" data-aos-delay="800">
                Our transparency in our engagement with you across your terms,
                operations, reporting and all aspects of business to make us
                your trusted partner
              </h3>
            </div>
          </div>
          <div className="curve-line">
            <img src={Abline} alt="curve" />
          </div>
        </div>
        <div className="container">
          <div className="row-6">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="300" data-aos-offset="200">
                  Proven track record across various markets in India and
                  overseas for Indian music
                </h3>
              </div>
              <div
                className="anim-img"
                data-aos="fade-right"
                data-aos-delay="800"
                data-aos-easing="ease-in-out"
              >
                <img src={Dv5} alt="anim" />
              </div>
            </div>
          </div>
        </div>
        <div className="row-7">
          <div className="container-fluid">
            <h3 data-aos="fade-up" data-aos-delay="300" data-aos-offset="200">
              Whether you are a film production house, music rights holder,
              record label or an artist, we have worked with all categories of
              clients and cater to each of their requirements.
            </h3>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default WhyDivo;
