import React from "react";
import { Link } from "react-router-dom";
import Footlogo from "../../images/footer-logo.png";
import {
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import DivoLogo from "../../images/divo.jpg";

const Footer = () => {
  return (
    <div className="footer container">
      <div className="footer-logo">
        <img src={Footlogo} alt="logo" />
      </div>
      <div className="footer-content">
        <div className="footer-text">
          <p>
            Get your music out worldwide and earn royalties across all possible
            revenue and royalty stream
          </p>
          <div className="icons">
            <a href="https://www.youtube.com/user/divotamilmovies">
              <FaYoutube className="youtube" />
            </a>
            <a href="https://twitter.com/divomusicindia">
              <FaTwitter className="youtube" />
            </a>
            <a href="https://www.instagram.com/divomusicindia/">
              {" "}
              <FaInstagram className="youtube" />
            </a>
            <a href="https://www.facebook.com/divomusicindia/ ">
              {" "}
              <FaFacebookF className="youtube" />
            </a>
            <FaLinkedinIn className="youtube" />
          </div>
        </div>
      </div>
      <div className="footer-nav">
        <ul className="ul-list">
          <li>
            <Link to="/why-divo" className="link-tag">
              Why divo music?
            </Link>
          </li>
          <li>
            <Link to="/distribution" className="link-tag">
              Distribution
            </Link>
          </li>
          <li>
            <Link to="/royalties" className="link-tag">
              Publishing Royalties
            </Link>
          </li>
          <li>
            <Link to="/partners" className="link-tag">
              Partners
            </Link>
          </li>
          <li>
            <Link to="/contact" className="link-tag">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <img
        style={{ display: "none" }}
        src={DivoLogo}
        alt="divo-logo"
        className="logo"
      />
    </div>
  );
};
export default Footer;
