import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../Components/Common/Layout';
import Banner from '../images/distribution/distribution-banner.jpg';
import map from '../images/distribution/dspmap.png';
import line from '../images/dbline.png';
import apple from '../images/distribution/applemusic.png';
import facebook from '../images/distribution/facebook.png';
import gaana from '../images/distribution/gaana.png';
import instagram from '../images/distribution/instagram.png';
import saavn from '../images/distribution/saavn.png';
import tiktok from '../images/distribution/tiktok.png';
import youtube from '../images/distribution/youtube.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Distribution() {
  return (
    <div className="distribution">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Distribution</title>
      </Helmet>
      <Layout>
        <div className="banner">
          <img src={Banner} alt="banner" />
          <div className="banner-text container  ">
            <h3
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Distribution
            </h3>
          </div>
        </div>

        <div className="bg-img ">
          <div className="row-1 container">
            <div className="row-content">
              <div className="text">
                <h3 data-aos="fade-down" data-aos-delay="300" data-aos-offset="200">
                  Digital stores distribution to ALL music DSPs worldwide
                </h3>
              </div>
              <div
                className="anim-img"
                data-aos="fade-down"
                data-aos-delay="1200"
                data-aos-easing="ease-in-out"
              >
                <img src={map} alt="anim" />
              </div>
            </div>
          </div>
          <div className="row-2 ">
            <div className="cu-line">
              <img src={line} alt="curve" />
            </div>
            <div className="row-content container">
              <div className="double-rows">
                <div className="platform-1 rows">
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="200"
                    >
                      <img src={youtube} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="300" data-aos-offset="200"
                    >
                      <img src={facebook} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="400"
                    >
                      <img src={instagram} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="500"
                    >
                      <img src={tiktok} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="600"
                    >
                      <img src={saavn} alt="Facebook" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="700"
                    >
                      <img src={gaana} alt="player" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="800"
                    >
                      <img src={apple} alt="tiktok" />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="800"
                    >
                      <img src />
                    </div>
                  </div>
                  <div className="text-img">
                    <div
                      className="icon"
                      data-aos="fade-right"
                      data-aos-delay="800"
                    >
                      <img src />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text">
                <h3 data-aos="fade-right" data-aos-delay="1000">
                  Video platforms including YouTube, Facebook, Instagram,
                  Tiktok, JioSaavn, Gaana, Apple Music, etc
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="rebanner">
          <div className="container-fluid">
            <div className="rebanner-img"></div>
            <div className="rebanner-content">
              <h3>
                Mobile VAS distribution for Ring back tone service on operators
                in India, Malaysia, Singapore, Sri Lanka, Gulf.
              </h3>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Distribution;
