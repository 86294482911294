import React from 'react';
import {Helmet} from "react-helmet";
import Layout from '../Components/Common/Layout';
import banner_image from '../images/home-banner.jpg';

function Banner() {
  return (
    <div className="Banner-divo">
        <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
            </Helmet>
      <div className="banner">
        <Layout removeFooter pageClass={Banner}>
          <div>
            <div className="banner_img">
              <img src={banner_image} alt="banner_image" />
            </div>
            <div className="banner_text container">
              <div>
                <h3>
                  Leading Independent Music Distributor, Record Label &
                  Publisher in India
                </h3>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
}

export default Banner;
