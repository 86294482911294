import React from 'react'
import logo from "../../images/logo.png"
import footerlogo from "../../images/footer-logo.png"
export default function Loader() {


    return (
        <div class="loader">
        <div class="loder_animation">
            <div class="loader_inner">
                <div class="fill">
                    <img src={logo} alt="" class="ldfill" />
                </div>
                <img src={footerlogo} alt="" class="ldgrey" />
            </div>
        </div>
    </div>
    )
}
