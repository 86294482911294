import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Logo from '../../images/logo.svg';
import Logo from "../../images/logo.png";

const Nav = () => {
  const [active, setActive] = useState(false);

  // const handleChange =(event) => {
  //   setActive(true);
  //   event.preventDefault();
  // }

  return (
    <div className="nav container">
      <div className="nav-logo">
        <a href="/">
          <img src={Logo} alt="logo" className="logo" />
        </a>
      </div>
      <div class="mobile_menu">
        <a
          href="#"
          className={active === true ? "menu_toggle active" : "menu_toggle"}
          onClick={() => setActive(!active)}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <ul className={active === true ? "ul-list active" : "ul-list"}>
        <li>
          <Link to="/why-divo" className="link-tag">
            Why Divo Music?
          </Link>
        </li>
        <li>
          <Link to="/distribution" className="link-tag">
            Distribution
          </Link>
        </li>
        <li>
          <Link to="/royalties" className="link-tag">
            Publishing Royalties
          </Link>
        </li>
        <li>
          <Link to="/partners" className="link-tag">
            Partners
          </Link>
        </li>
        <li>
          <Link to="/contact" className="link-tag">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
