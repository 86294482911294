import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import Loader from "./Loader"

const Layout = ({ children, pageClass, removeFooter, removeNav }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1500);
  }, []);

  if(!isLoaded) {
    return(<Loader></Loader>)
  } 
    
  return (
    <div id="page" className={pageClass}>
      {removeNav ? null : <Nav />}
      {children}
      {removeFooter ? null : <Footer />}
    </div>
  );
};

export default Layout;
