
import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import reportWebVitals from "./reportWebVitals";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Banner";
import WhyDivo from "./pages/WhyDivo";
import Partners from "./pages/Partners";
import Contact from "./pages/Contact";
import Distribution from "./pages/Distribution";
import Publishingroyalties from "./pages/PublishingRoyalties"


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/why-divo" component={WhyDivo} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/royalties" component={Publishingroyalties} />
        <Route exact path="/distribution" component={Distribution} />

      </Switch>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();